import { Box, Typography } from '@mui/material';

import chewy from '../../assets/chewy.png';
import hills from '../../assets/hills.png';
import instinct from '../../assets/instinct.png';

import medvet from '../../assets/medvet.png';
import merck from '../../assets/merck.png';
import petsmart from '../../assets/petsmart.png';
import purin from '../../assets/purin.png';
const TrustedBy = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '350px',
        py: { xs: '48px', lg: 0 },
        px: { xs: 0, lg: 2 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: 'Inter, serif',
          color: 'rgba(0, 6, 38, 0.9)',
          fontSize: { xs: '36px', lg: '48px' },
          letterSpacing: '-3%',
          fontWeight: 600
        }}
      >
        Trusted by
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '32px', lg: '99px' },
          flexWrap: 'wrap',
          mt: 2
        }}
      >
        <Box component="img" src={purin} alt="Purina" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={chewy} alt="Chewy" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={hills} alt="Hills" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={medvet} alt="MedVet" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={merck} alt="Merck" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={instinct} alt="Instinct" sx={{ width: '122.44px', height: '62px' }} />
        <Box component="img" src={petsmart} alt="PetSmart" sx={{ width: '122.44px', height: '62px' }} />
      </Box>
    </Box>
  );
};

export default TrustedBy;
