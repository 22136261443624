import { Box, Typography } from '@mui/material';

import pawlogo from '../../assets/Pawlogo.png';

const Copyright = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        py: 8,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderTop: '1px solid #E5E7EB'
      }}
    >
      <Box
        component="img"
        src={pawlogo}
        alt="Paw AI Logo"
        sx={{
          width: '60px',
          height: '60px',
          mb: 2
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#6B7280',
          textAlign: 'center',
          mb: 0.5
        }}
      >
        © 2024 PawAI.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#6B7280',
          textAlign: 'center',
          mb: 2
        }}
      >
        All Rights Reserved.
      </Typography>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Typography
          variant="body2"
          component="a"
          href="/privacy-policy"
          sx={{
            color: '#6B7280',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="body2"
          component="a"
          href="/terms-of-use"
          sx={{
            color: '#6B7280',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' }
          }}
        >
          Terms of Use
        </Typography>
      </Box>
    </Box>
  );
};

export default Copyright;
