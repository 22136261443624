import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  TextField,
} from '@mui/material';

export default function SearchStream() {
  const [query, setQuery] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const abortControllerRef = useRef(null);

  const handleStartStream = async () => {
    setOutput('');
    setError('');

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setIsLoading(true);

    try {
      const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';
      const response = await fetch(
        `${API_URL}/pawai-backend2/search/streamingresponse`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query }),
          signal: abortController.signal, 
        }
      );

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Request failed with status ${response.status}: ${text}`);
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break; 


        const chunk = decoder.decode(value, { stream: true });
        setOutput((prev) => prev + chunk);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        setOutput((prev) => prev + '\n\n[Stream aborted by user]');
      } else {
        console.error('Error:', err);
        setError(`Error: ${err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    // Abort any ongoing fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setOutput('');
    setError('');
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Test Streaming Endpoint
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <CircularProgress />
          </Box>
        )}

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleStartStream();
          }}
          sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
        >
          <TextField
            fullWidth
            label="Query"
            placeholder="e.g., Common causes of dog infection"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Streaming...' : 'Start Stream'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClear}>
              Clear
            </Button>
          </Box>
        </Box>
      </Paper>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Chatbot Response:
        </Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            p: 2,
            height: 300,
            overflowY: 'auto',
            whiteSpace: 'pre-wrap',
            backgroundColor: '#f9f9f9',
          }}
        >
          {output}
        </Box>
      </Paper>
    </Box>
  );
}
