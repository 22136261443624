import { Box, Button, Container, Typography } from '@mui/material';

const DemoCTA = () => {
  return (
    <Container
      component="section"
      sx={{
        mb: { xs: 46, md: 0 },
        minWidth: '100vw',
        height: 'auto',
        minHeight: '408px',
        pb: { md: '128px' },
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: '408px',
          py: 8,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: { xs: '32px', lg: '64px' }
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Inter, serif',
              color: 'rgba(0, 6, 38, 0.9)',
              mb: '16px',
              fontSize: { xs: '32px', lg: '48px' },
              fontWeight: 700,
              lineHeight: { xs: '48px', lg: '64px' },
              letterSpacing: '-3%',
              maxWidth: '780px',
              textAlign: 'center'
            }}
          >
            Seeing is Believing! Schedule a Demo to see PawPilot AI in Action!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: 'Inter, serif',
              fontSize: { xs: '18px', lg: '20px' },
              color: 'rgba(0, 9, 51, 0.65)',
              maxWidth: '780px'
            }}
          >
            Click the link below to schedule a 30 min call where we get to know your needs and walk you through a demo
            of PawPilot AI!
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            maxWidth: '960px',
            maxHeight: '405px',
            borderRadius: '16px',
            overflow: { lg: 'hidden' },
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box
            component="img"
            src={require('../../assets/Dog2.png')}
            alt="Demo"
            sx={{
              width: { xs: '100%', md: '50%' },
              height: 'auto',
              objectFit: 'cover'
            }}
          />
          <Box
            sx={{
              minHeight: '408px',
              backgroundColor: 'rgba(31, 36, 60, 1)',
              color: '#FFFFFF',
              width: { xs: '100%', md: '50%', lg: '422px' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              p: { xs: 0, lg: '0 32px 8px 32px' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                mb: '64px',
                padding: { xs: '0 24px 24px 24px', md: 0 }
              }}
            >
              <img
                src={require('../../assets/calendar_cta.png')}
                alt="Calendar Icon"
                style={{ width: '48px', height: '48px', marginBottom: '16px' }}
              />

              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Inter, serif',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '24px',
                  fontWeight: '700',
                  mb: 1
                }}
              >
                PawPilot AI: Demo Meeting
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, serif',
                  color: 'rgba(255, 255, 255, 0.78)',
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  alignSelf: 'center'
                }}
              >
                Discover how PawPilot AI revolutionizes veterinary care by analyzing blood work in just 10 seconds,
                compared to the traditional 5&#45;10 minutes.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                width: { xs: '350px', lg: '100%' },
                height: { xs: 'auto', lg: '68px' },
                fontFamily: 'Inter, serif',
                backgroundColor: '#4C64D9',
                color: '#FFFFFF',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: '12px',
                px: 3,
                py: 1.5
              }}
            >
              Book Your Call Now!
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DemoCTA;
