import { Box } from '@mui/material';
import React from 'react';

import About from './Landing/About';
import Copyright from './Landing/Copyright';
import DemoCTA from './Landing/DemoCTA';
import FAQ from './Landing/FAQ';
import Hero from './Landing/Hero';
import PawDifference from './Landing/PawDifference';
import TrustedBy from './Landing/TrustedBy';

const LandingPage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Hero />
      <TrustedBy />
      <PawDifference />
      <DemoCTA />
      <About />
      <FAQ />
      <Copyright />
    </Box>
  );
};

export default LandingPage;
