import { Box, Typography } from '@mui/material';

const PawDifference = () => {
  // Helper function to calculate different gradient backgrounds  for various cards
  const cardGradientHandler = index => {
    if (index === 0) {
      return 'linear-gradient(180deg, rgba(234, 221, 255, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    } else if (index === 1) {
      return 'linear-gradient(180deg, rgba(221, 229, 255, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    } else {
      return 'linear-gradient(180deg, rgba(255, 227, 221, 0.8) 0%, rgba(255, 255, 255, 0) 163.71%)';
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'auto', lg: '776px' },
        padding: { xs: '64px 0 64px 0', lg: '128px 0 0 0' },
        backgroundColor: 'rgba(245, 246, 250, 1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter, serif',
          fontWeight: 'bold',
          mb: 3,
          fontSize: '32px',
          lineHeight: '40px',
          color: '#1E223A'
        }}
      >
        The PawPilot AI Difference
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '24px',
          mt: 4,
          mx: 'auto',
          px: 2
        }}
      >
        {[
          {
            subtitle: 'Precision',
            title: 'AI Diagnostics',
            firstText: `Our AI analyzes symptoms and health data to provide`,
            boldText: 'rapid, accurate diagnostic suggestions,',
            finalText: ', helping veterinarians make informed decisions quickly and effectively.'
          },
          {
            subtitle: 'Efficient Patient Management',
            title: 'Streamlined Pet Care Workflow',
            boldText: 'Optimize your clinic’s operations',
            firstText: 'with our system that manages appointments, tracks patient histories, and more,',
            finalText: 'freeing up more time for hands-on pet care.'
          },
          {
            subtitle: 'AI X-Ray & Lab Analysis',
            title: 'Advanced Imaging & Test Analysis',
            boldText: 'Leverage our AI’s ability to quickly analyze X-rays and lab results',
            firstText: ', highlighting potential issues and providing detailed reports',
            finalText: 'to support your clinical decision-making process.'
          }
        ].map(({ subtitle, title, firstText, boldText, finalText }, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '100%', sm: '300px', lg: '368px' },
              height: { xs: '322px', lg: '408px' },
              borderRadius: '12px',
              backgroundColor: '#F3F5FF',
              border: '1px solid rgba(0, 17, 102, 0.1)',
              borderRadius: '12px',
              boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.04)',
              boxShadow: '0px 2px 4px -2px rgba(0, 0, 0, 0.08)',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 1))',
                width: { xs: '382px', sm: '300px', lg: '360px' },
                height: { xs: '314px', lg: '400px' },
                display: 'flex',
                ml: { xs: 0, lg: 0 },
                mt: '4px'
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid rgba(0, 17, 102, 0.1)',
                  width: { xs: '100%', sm: '300px', lg: '360px' },
                  borderRadius: '8px'
                }}
              >
                <Box
                  sx={{
                    background: cardGradientHandler(index),
                    height: '132px',
                    width: { xs: '382px', sm: '300px', lg: '346px' },
                    padding: { xs: '24px 0 0 0', md: '24px 0 0 12px' },
                    borderBottom: '1px solid rgba(0, 17, 102, 0.1)',
                    borderRadius: '8px 0 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: 'Inter, serif',
                      color: 'rgba(0, 9, 51, 0.65)',
                      fontWeight: '400',
                      letterSpacing: '-2%',
                      mb: 1,
                      fontSize: '16px',
                      pl: { xs: '16px', md: 0 }
                    }}
                  >
                    {subtitle}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Inter, serif',
                      fontWeight: 'bold',
                      mb: 2,
                      fontSize: '24px',
                      lineHeight: '28px',
                      letterSpacing: '-3%',
                      color: 'rgba(0, 6, 38, 0.9)',
                      pl: { xs: '16px', md: 0 }
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mb: { xs: index === 1 ? '22px' : '20px', lg: '100px' },
                    width: '100%',
                    borderBottom: '1px solid rgba(0, 17, 102, 0.1)'
                  }}
                />
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Inter, serif',
                      color: '#555',
                      textAlign: 'left',
                      fontSize: '16px',
                      pl: '12px',
                      pr: '12px',
                      lineHeight: '24px',
                      maxWidth: '328px'
                    }}
                  >
                    {index === 0 ? (
                      <div>
                        {firstText} <b>{boldText}</b> {finalText}
                      </div>
                    ) : (
                      <div>
                        <b>{boldText}</b> {firstText} <b>{finalText}</b>
                      </div>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PawDifference;
