import { Box, Typography } from '@mui/material';

const About = () => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#FFFFFF',
        minWidth: '100vw',
        height: 'auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          padding: '0 0 128px 0'
        }}
      >
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: 'Inter, serif',
              fontWeight: 500,
              fontSize: '20px',
              color: 'rgba(0, 9, 51, 0.65)',
              pb: '12px'
            }}
          >
            Introducing
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Inter, serif',
              fontSize: '48px',
              fontWeight: '700',
              color: 'rgba(0, 6, 38, 0.9)',
              letterSpacing: '-3%'
            }}
          >
            Who We Are
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Box
            component="img"
            src={require('../../assets/founder.png')}
            alt="Founder and Team"
            sx={{
              width: { xs: '100%', md: '550px', lg: '702px' },
              height: { xs: 'auto', md: '830px' },
              alignSelf: 'flex-start',
              borderRadius: '8px',
              objectFit: 'cover',
              pl: { lg: 18 },
              mb: { xs: 4, md: 0 }
            }}
          />
          <Box
            sx={{
              width: { xs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              ml: { xs: '16px', lg: '48px' }
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter, serif',
                maxWidth: { xs: '400px', lg: '590px' },
                fontWeight: 600,
                fontSize: { xs: '18px', lg: '20px' },
                lineHeight: { xs: '24px', lg: '30px' },
                color: 'rgba(0, 6, 38, 0.9)',
                mb: { xs: 3, md: 4, lg: 6 }
              }}
            >
              We&#39;re more than just a tech company—we&#39;re a team with a deep connection to veterinary care. Our
              mission is to make veterinarians' lives easier by providing instant, AI&#45;powered insights to enhance
              their decision&#45;making, ultimately improving animal health and clinic efficiency.
            </Typography>
            <Box sx={{ mb: 4, textAlign: 'left', maxWidth: { xs: '400px', lg: '590px' } }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: 'rgba(0, 0, 0, 1)',
                  mb: '10px'
                }}
              >
                Bryan &#45; Founder
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 9, 51, 0.65)',
                  mb: 2
                }}
              >
                As the founder of PawPilot AI, I bring a background in software and technology, with a focus on
                simplifying complex workflows. My experience in data management and AI technology has driven me to
                create a solution that empowers veterinary practices to provide even better care for their patients.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 9, 51, 0.65)'
                }}
              >
                I&#39;m passionate about leveraging technology to solve real&#45;world problems and making sure our
                platform is as user&#45;friendly and effective as possible.
              </Typography>
            </Box>
            <Box sx={{ maxWidth: { xs: '400px', lg: '590px' }, textAlign: 'left' }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: 'rgba(0, 0, 0, 1)',
                  mb: '10px'
                }}
              >
                Melissa, DVM &#45; Certified Veterinarian
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 9, 51, 0.65)',
                  mb: 2
                }}
              >
                Melissa is not only my fiancée but also a highly experienced veterinarian. She brings firsthand
                knowledge of the challenges that veterinarians face every day, from diagnosing complex cases to managing
                the operational side of a practice. Her deep understanding of veterinary medicine shapes every aspect of
                PawPilot AI, ensuring that the platform truly addresses the needs of the professionals who use it.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, serif',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'rgba(0, 9, 51, 0.65)'
                }}
              >
                Together, we combine technology and veterinary expertise to build a tool that not only improves clinical
                outcomes but also makes day&#45;to&#45;day tasks easier for veterinary teams.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
