import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import animalsIcon from '../../assets/Animals.png';
import catIcon from '../../assets/Cat.png';
import dogIcon from '../../assets/Dog.png';
import landing1 from '../../assets/Landing1.png';
import landing2 from '../../assets/Landing2.png';
import pawicon from '../../assets/PawAI.png';

const Hero = () => {
  const navigate = useNavigate();

  const handleLogin = () => navigate('/login');
  const handleSignup = () => navigate('/signup');
  return (
    <Box
      component="section"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
        background: 'linear-gradient(rgba(76, 100, 217, 0.6), rgba(245, 246, 250, 1))',
        display: 'flex',
        flexDirection: 'column',
        pb: { xs: 30, lg: 3 }
      }}
    >
      {/* Taskbar */}
      <Box
        sx={{
          width: '100%',
          height: '60px',
          px: '32px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #ccc',
          zIndex: 1000
        }}
      >
        <Box component="img" src={pawicon} alt="Paw AI Logo" sx={{ height: '48px', width: 'auto' }} />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLogin}
            sx={{
              width: '69x',
              height: '32px',
              borderRadius: '8px',
              textTransform: 'none',
              borderColor: 'rgba(76, 100, 217, 0.8)',
              color: 'rgba(76, 100, 217, 1)',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '14px',
              boxShadow: 'rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.08)'
            }}
          >
            Log in
          </Button>
          <Button
            disabled
            variant="contained"
            onClick={handleSignup}
            sx={{
              width: '87px',
              height: '32px',
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '14px',
              backgroundColor: 'rgba(76, 100, 217, 1)',
              color: '#fff',
              marginRight: '50px'
            }}
          >
            Sign up
          </Button>
        </Box>
      </Box>

      {/* Hero Content */}
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          py: { xs: 4, md: 10, lg: 0 },
          mt: { xs: 8, md: 0, lg: 8 }
        }}
      >
        <Container sx={{ width: '100%', height: 'auto', mb: { lg: 3 } }}>
          <Box
            component="img"
            src={animalsIcon}
            alt="Animals"
            sx={{
              margin: '0 auto',
              width: { xs: '100px', sm: '200px', lg: '112px' },
              height: { lg: '64px' },
              maxWidth: '35%',
              display: { xs: 'none', sm: 'block' }
            }}
          />
        </Container>
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Inter, serif',
            mt: { lg: '40px' },
            fontSize: '46px',
            fontWeight: '800',
            letterSpacing: '-1px',
            mb: 4,
            maxWidth: '800px',
            color: 'rgba(0,6,38,0.9)'
          }}
        >
          Paw AI &#45; The Future of Veterinary Diagnostics
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'Inter, serif',
            mb: 5,
            maxWidth: '540px',
            color: 'rgba(0,9,51,0.65)',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '32px',
            mx: 'auto'
          }}
        >
          Enhance your clinic&#39;s diagnostics, offering advanced care and peace of mind.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Button
            disabled
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSignup}
            sx={{
              fontFamily: 'Inter, serif',
              width: '213px',
              height: '48px',
              borderRadius: '16px',
              backgroundColor: 'rgba(1, 13, 73, 0.9)',
              color: '#fff',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 600,
              boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.04), 0px 2px 4px -2px rgba(0, 0, 0, 0.08)'
            }}
          >
            Sign up Now!
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleLogin}
            sx={{
              fontFamily: 'Inter, serif',
              width: '213px',
              height: '44px',
              borderRadius: '16px',
              textTransform: 'none',
              color: 'rgba(0, 6, 38, 0.9)',
              fontSize: '16px',
              fontWeight: 600,
              border: '2px solid rgba(0, 0, 0, 0.3)',
              mb: { xs: '10px', lg: '32px' },
              boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.04), 0px 2px 4px -2px rgba(0, 0, 0, 0.08)'
            }}
          >
            Log In
          </Button>

          <Box sx={{ mb: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '540px' }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Gochi Hand',
                fontWeight: 400,
                color: 'rgba(0,9,51,0.65)',
                width: '341px',
                fontSize: '16px',
                lineHeight: '16px',
                letterSpacing: '2px',
                mb: '6px'
              }}
            >
              trusted by:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter, serif',
                width: { xs: '278px', lg: '341px' },
                maxWidth: '540px',
                fontSize: '12px',
                lineHeight: '20px',
                color: 'rgba(0,9,51,0.65)'
              }}
            >
              Board Certified Veterinarian, Pet Owners, & all Animal lovers.
            </Typography>
          </Box>
        </Box>
      </Container>

      {/* Absolutely Positioned Images */}
      <Box
        component="img"
        src={landing1}
        alt="Dog card overlay"
        sx={{
          position: 'absolute',
          left: { xs: '5%', lg: '7.5%' },
          bottom: { xs: '2.5%', sm: 0, lg: '19%' },
          width: { xs: '220px', sm: '380px', lg: '338px' },
          height: { xs: '107.5px', lg: '138px' },
          zIndex: 2
        }}
      />
      <Box
        component="img"
        src={landing2}
        alt="Cat card overlay"
        sx={{
          position: 'absolute',
          right: { xs: '3%', lg: '1.5%' },
          bottom: { xs: '12%', lg: '25%' },
          width: { xs: '220px', sm: '380px', lg: '338px' },
          height: { xs: '150px', lg: '175px' }
        }}
      />
      <Box
        component="img"
        src={dogIcon}
        alt="Dog"
        sx={{
          position: 'absolute',
          left: 0,
          bottom: '20px',
          width: { xs: '150px', sm: '300px' },
          maxWidth: '35%',
          ml: '50px',
          zIndex: 1,
          display: { xs: 'none', sm: 'block' }
        }}
      />
      <Box
        component="img"
        src={catIcon}
        alt="Cat"
        sx={{
          position: 'absolute',
          right: '2%',
          bottom: '20px',
          height: { lg: '245px' },
          width: { xs: '150px', sm: '300px' },
          maxWidth: '35%',
          display: { xs: 'none', sm: 'block' }
        }}
      />
    </Box>
  );
};

export default Hero;
