import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      component="section"
      sx={{
        width: '100%',
        py: 8,
        backgroundColor: 'rgba(245, 246, 250, 1)'
      }}
    >
      <Container
        sx={{
          width: '100%',
          maxWidth: '960px',
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: '32px',
            fontWeight: 'bold',
            mb: 2,
            color: 'inherit'
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '18px',
            mb: 4,
            color: 'inherit'
          }}
        >
          Get help anytime &#45; contact our concierge for support.
        </Typography>
        <Box>
          {[
            {
              question: 'What is Paw AI?',
              answer:
                'Paw AI is an AI-driven platform designed to help veterinarians streamline their workflows and deliver more accurate care.',
              panel: 'panel1'
            },
            {
              question: 'How does Paw AI improve diagnostic accuracy?',
              answer:
                'Our machine learning algorithms analyze large datasets of veterinary cases for fast, accurate diagnostics.',
              panel: 'panel2'
            },
            {
              question: 'Can Paw AI integrate with existing veterinary management systems?',
              answer:
                'Yes, Paw AI is designed to seamlessly integrate with most veterinary management systems to enhance workflow efficiency.',
              panel: 'panel3'
            },
            {
              question: 'How secure is the data stored on Paw AI?',
              answer:
                'Paw AI ensures top-level data security, complying with industry standards to protect sensitive information.',
              panel: 'panel4'
            },
            {
              question: 'Is Paw AI suitable for all types of veterinary practices?',
              answer:
                'Yes, Paw AI is adaptable for practices of all sizes, providing solutions for both small clinics and larger animal hospitals.',
              panel: 'panel5'
            }
          ].map(({ question, answer, panel }) => (
            <Accordion
              key={panel}
              expanded={expanded === panel}
              onChange={handleChange(panel)}
              sx={{
                background: '#0015800A',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                '&:before': { display: 'none' }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  padding: '0 16px',
                  '& .MuiAccordionSummary-content': { margin: 0 }
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: '500', color: 'inherit' }}>
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '8px 16px', color: 'inherit' }}>
                <Typography variant="body2">{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
